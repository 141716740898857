import dayjs from 'dayjs';
import { isNotEmpty } from '@/util/util';
import _ from 'lodash';

function emptyOr(v) {
  return isNotEmpty(v) ? v : null;
}

export const reportForApi = (report) => {
  const result = report.exportForApi();
  result.date = emptyOr(result.date);
  result.hour = emptyOr(result.hour);
  result.minute = emptyOr(result.minute);
  result.time = emptyOr(result.time);
  result.fee = emptyOr(result.fee);
  result.travelCost = emptyOr(result.travelCost);
  result.nextDate = emptyOr(result.nextDate);
  result.nextHour = emptyOr(result.nextHour);
  result.nextMinute = emptyOr(result.nextMinute);
  return result;
};

export class Report {
  constructor(param = { reportProgress: [] }, omitSubject = false) {
    this.id = param.id || null;
    this.date = param.date || '';
    this.hour = isNotEmpty(param.hour) ? param.hour : '';
    this.minute = isNotEmpty(param.minute) ? param.minute : '';
    this.time = isNotEmpty(param.time) ? param.time : '';
    this.content = param.content || '';
    this.teacherEvaluation = param.teacherEvaluation || '';
    this.fee = isNotEmpty(param.fee) ? param.fee : '';
    this.travelCost = isNotEmpty(param.travelCost) ? param.travelCost : '';
    this.nextDate = param.nextDate || '';
    this.nextHour = isNotEmpty(param.nextHour) ? param.nextHour : '';
    this.nextMinute = isNotEmpty(param.nextMinute) ? param.nextMinute : '';
    this.nextInstructions = param.nextInstructions || '';
    this.isTurnIn = param.isTurnIn || false;
    this.isApproval = param.isApproval || false;
    this.teacherToFamilyComment = param.teacherToFamilyComment || '';
    this.teacherToHeadquartersComment = param.teacherToHeadquartersComment || '';
    this.familyToTeacherComment = param.familyToTeacherComment || '';
    this.settlement = param.settlementReport ? param.settlementReport.settlement : null;
    if (!omitSubject) {
      this.reportProgress = param.reportProgress.map((r) => new ReportProgress(r));
    } else {
      this.reportProgress = [];
    }
    this.teacherQuestionnaires = param.teacherQuestionnairesJson || this.getInitalQuestionnaires();
    this.removeTexts = [];
  }

  mergeQuestionnaires() {
    const q = new Questionnaires();
    q.merge(this.teacherQuestionnaires, window.env.teacherQuestionnaires);
    this.teacherQuestionnaires = q;
  }

  copyPrevProgress(prev) {
    this.reportProgress = prev.reportProgress.map((r) => {
      r.id = null;
      r.page = '';
      r.points = '';
      r.learningUnit = '';
      return r;
    });
  }

  getInitalQuestionnaires() {
    const q = new Questionnaires();
    q.merge({}, window.env.teacherQuestionnaires);
    return q;
  }

  exportForApi() {
    return {
      ...this,
      reportProgress: _.map(this.reportProgress, (p) => {
        return p.exportForApi();
      }),
    };
  }
}

class Questionnaires {
  constructor() {
    this.version = '';
    this.inquiries = [];
  }

  merge(apiData, envData) {
    this.version = envData.version;
    if (apiData.version === envData.version) {
      this.inquiries = envData.inquiries.map((r) => new Inquiry(r, apiData));
      return;
    }
    this.inquiries = envData.inquiries.map((r) => new Inquiry(r, { inquiries: [] }));
  }
}

class Inquiry {
  constructor(envData, apiData) {
    this.inquiry = envData.inquiry || '';
    const d = apiData.inquiries.find((r) => r.inquiry === envData.inquiry);
    this.answer = d ? d.answer : '';
    this.answers = envData.answers || [];
  }
}

export class ReportProgress {
  constructor(param = {}) {
    this.id = param.id || null;
    this.code = param.code || '';
    this.textName = param.textName || '';
    this.gradeName = param.gradeName || '';
    this.subjectName = param.subjectName || '';
    this.page = isNotEmpty(param.page) ? param.page : '';
    this.maxPage = isNotEmpty(param.maxPage) ? param.maxPage : '';
    this.points = param.points || '';
    this.learningUnit = param.learningUnit || '';
  }

  exportForApi() {
    console.log('-----------');
    console.log(this);
    return {
      id: this.id || null,
      code: this.code || null,
      textName: this.textName || null,
      gradeName: this.gradeName || null,
      subjectName: this.subjectName || null,
      page: this.page !== '' ? this.page : null,
      maxPage: this.maxPage || null,
      points: this.points || null,
      learningUnit: this.learningUnit || null,
    };
  }
}

export class Exam {
  constructor(param = { examSubjects: [], examImages: [] }, isCopy = false) {
    this.id = param.id || null;
    this.date = param.date || '';
    this.name = param.name || '';
    this.examSubjects = (param.examSubjects || []).map((r) => new ExamSubject(r));
    this.examImages = (param.examImages || []).map((r) => new ExamImage(r));
    if (isCopy) {
      this.id = null;
      this.date = '';
      this.name = '';
      this.examSubjects.map((r) => {
        r.id = null;
        r.score = '';
        return r;
      });
      this.examImages = [];
    }
  }
}

export class ExamSubject {
  constructor(param = {}) {
    this.id = param.id || null;
    this.subjectName = param.subjectName || '';
    this.score = param.score || '';
    this.maxScore = param.maxScore || '';
  }
}

export class ExamImage {
  constructor(param = {}) {
    this.id = param.id || null;
    this.name = param.name || '';
    this.mime = param.mime || null;
  }
}

export class Record {
  constructor(param = { recordSubjects: [], recordImages: [] }, isCopy = false) {
    this.id = param.id || null;
    this.date = param.date || '';
    this.maxPoints = param.maxPoints || '';
    this.recordSubjects = param.recordSubjects.map((r) => new RecordSubject(r));
    this.recordImages = param.recordImages.map((r) => new RecordImage(r));
    if (isCopy) {
      this.id = null;
      this.date = '';
      this.recordSubjects.map((r) => {
        r.id = null;
        r.points = '';
        return r;
      });
      this.recordImages = [];
    }
  }
}

export class RecordSubject {
  constructor(param = {}) {
    this.id = param.id || null;
    this.subjectName = param.subjectName || '';
    this.points = param.points || '';
  }
}

export class RecordImage {
  constructor(param = {}) {
    this.id = param.id || null;
    this.name = param.name || '';
    this.mime = param.mime || null;
  }
}

export class TextBook {
  constructor(param = {}) {
    this.code = param.code || null;
    this.textName = param.textName || '';
    this.gradeName = param.gradeName || '';
    this.subjectName = param.subjectName || '';
  }
}

export class Settlement {
  constructor(param = { reports: [] }) {
    this.id = param.id || null;
    this.requestDate = param.requestDate || dayjs(new Date()).format('YYYY-MM-DD');
    this.fee = param.fee || 0;
    this.isPaymented = param.isPaymented || false;
    this.reports = param.reports.map((r) => new Report(r, true));
    this.sumFee = this.summaryFee();
    this.sumTravelCost = this.summaryTravelCost();
    this.grandTotal = this.sumFee + this.sumTravelCost;
  }

  summaryFee() {
    return _.reduce(this.reports, (a, r) => a + Number.parseInt(r.fee || '0'), 0);
  }

  summaryTravelCost() {
    return _.reduce(this.reports, (a, r) => a + Number.parseInt(r.travelCost || '0'), 0);
  }

  setUnsettledReports(reports) {
    this.reports = reports.map((r) => new Report(r, true));
    this.sumFee = this.summaryFee();
    this.sumTravelCost = this.summaryTravelCost();
    this.grandTotal = this.sumFee + this.sumTravelCost;
    this.fee = this.grandTotal;
  }
}
